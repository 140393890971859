<template>
  <div id="certificate-image-container" class="absolute bg-secondary text-primary rounded opacity-0 -z-50">
    <div class="flex flex-col rounded border-2 border-[#3F51B5] p-2 gap-2 text-xs max-w-80 ym-hide-content">
      <div class="flex items-center gap-5">
        <es-logo class="w-12 h-12"/>
        <strong class="flex-grow">ДОКУМЕНТ ПОДПИСАН<br>ЭЛЕКТРОННОЙ ПОДПИСЬЮ</strong>
      </div>
      <es-h-divider class="bg-[#3F51B5]"/>
      <div class="flex flex-col gap-0.5">
        <div v-for="item in items" :key="item.title">
          <b>{{ item.title }}:&nbsp;</b>
          <span :class="{ 'italic': item.isDynamic && !certificateIsDynamic}">
            {{ item.value }}
          </span>
        </div>
        <small v-if="link" class="text-center text-accent-primary">{{ link }}</small>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import {computed, defineComponent, type PropType} from 'vue'
import { EsLogo, EsHDivider, type CertificateOption } from "@esigndoc/ui";
import dayjs from "dayjs";
import {useStore} from "@nanostores/vue";
import {$certificate, $certificateIsDynamic} from "@/stores/app-store.ts";
import {useLazyStore} from "@/utils/lazy-store.ts";

type SignatureImageItem = {
  title: string
  value: string
  isDynamic?: boolean
}

export default defineComponent({
  components: {EsLogo, EsHDivider},
  props: {
    additionalItems: {
      type: Array as PropType<SignatureImageItem[]>,
      default: () => []
    }
  },
  setup(props) {
    const link = undefined

    const certificate = useLazyStore($certificate, null)
    const certificateIsDynamic = useStore($certificateIsDynamic)

    const defaultItems = computed(() => [
      {title: 'ФИО', value: certificate.value?.title},
      {
        title: 'Срок действия',
        value: certificate.value?.validDateRange.map((_: string) => dayjs(_).format('DD.MM.YYYY')).join(' - ')
      },
      {
        title: 'Сертификат',
        value: certificate.value?.id.toUpperCase().match(/.{1,4}/g)?.join(' ') ?? certificate.value?.id.toUpperCase()
      },
      {
        title: 'Время подписания',
        value: certificateIsDynamic.value ? dayjs().format('DD.MM.YYYY HH:mm') : '<время>',
        isDynamic: true
      }
    ])

    const items = computed(() => [...defaultItems.value, ...props.additionalItems])

    return {items, link, certificateIsDynamic}
  }
})
</script>

<style scoped>

</style>
